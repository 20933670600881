import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    PageHeaderA,
    PageSection,
    Ul,
} from '../components/layout';
import { PageSubtitle, PageText, PageTitle } from '../components/typography';
import { ContactSection } from '../components/contact';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box } from '../components/box';
import { makeSectionId } from '../components/jumpboxlink';
import { ServiceJumpTable } from '../components/servicejumptable';
import Seo from '../components/seo';

function ThirdPartyManagement({ data, location }) {
    const { t } = useTranslation();
    const overview = [
        {
            title: t('third_party_title', 'Third Party Management'),
            desc: t(
                'third_party_desc',
                'Zulieferer und andere kritische Abhängigkeiten im Überblick behalten'
            ),
        },
        {
            title: t(
                'third_party_profile_title',
                'Third Party Security Profile'
            ),
            desc: t('third_party_profile_desc', 'Automatische On-Demand Security Einschätzung zu Ihren Zulieferern'),
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={bgImageDefault.platform}
            headerContent={
                <PageHeaderA
                    title={t('title', 'Third Party Management')}
                    text={t(
                        'subtitle',
                        'Risiken in der Supply-Chain identifizieren und mitigieren.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/thirdparty.jpg"
                            alt="Security-Rating"
                            loading="eager"
                            objectFit="cover"
                            placeholder="none"
                            className="aspect-video"
                            width={500}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Vulnerability Management')} />
            <PageSection size="big">
                <PageSubtitle className="max-w-3xl mt-4">
                    <Trans i18nKey="intro_1">
                        Kontrolle über wichtige Vertragspartner durch effiziente
                        Workflows und problemlose Sicherstellung und
                        kontinuierliche Überwachung Ihrer Unternehmensvorgaben.
                    </Trans>
                </PageSubtitle>
                <PageText>
                    <Trans i18nKey="intro_2">
                        Mit dem condignum Third Party Management sind Sie in der
                        Lage die Sicherheit Ihrer Supply Chain und sonstigen
                        Vertragspartner sicherzustellen. Sie behalten stets den
                        Überblick über kritische Abhängigkeiten, stellen die
                        Einhaltung Ihrer Unternehmensvorgaben bei Ihren Partnern
                        sicher und entscheiden sich bei Auswahlverfahren stets
                        für den besten Kandidaten.
                    </Trans>
                </PageText>
            </PageSection>

            <ServiceJumpTable items={overview} type="platform" />

            <PageSection size="big" id={makeSectionId(overview[0].title)}>
                <header>
                    <PageTitle>
                        <Trans i18nKey="third_party_title">
                            Third Party Management
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="third_party_desc">
                            Zulieferer und andere kritische Abhängigkeiten im
                            Überblick behalten
                        </Trans>
                    </PageSubtitle>
                </header>
                <PageText>
                    <Trans i18nKey="third_party_col1">
                        Überblick über die gesamte Supply-Chain bewahren
                        und kritische Elemente darin identifizieren,
                        bewerten und effizient managen.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="third_party_col2">
                        Die gesamte Supply-Chain ist stets im Blick und
                        kann effizient gemanagt werden. Kritische
                        Zulieferer und Vertragspartner werden nach
                        diversen Bewertungskriterien hervorgehoben und
                        können entsprechend behandelt werden.
                    </Trans>
                </PageText>
            </PageSection>

            <PageSection size="big" id={makeSectionId(overview[1].title)} isDark>
                <header>
                    <PageTitle>
                        <Trans i18nKey="third_party_profile_title">
                            Third Party Security Profile
                        </Trans>
                    </PageTitle>
                    <PageSubtitle>
                        <Trans i18nKey="third_party_profile_desc">
                            Automatische On-Demand Security Einschätzung zu Ihren Zulieferern
                        </Trans>
                    </PageSubtitle>
                    <PageText>
                        <Trans i18nKey="third_party_profile_p1">
                            Die condignum Vendor Security Profile ermöglichen eine automatische
                            On-Demand Sicherheitsbewertung Ihrer Zulieferer und Vertragspartner.
                        </Trans>
                    </PageText>
                    <PageText>
                        <Trans i18nKey="third_party_profile_p2">
                            Durch die automatisierte Bewertung von rund 90 unterschiedlichen
                            Prüfpunkten wird der Sicherheitszustand einer Organisation bewertet
                            und übersichtlich aufbereitet.
                        </Trans>
                    </PageText>
                    <PageText>
                        <Trans i18nKey="third_party_profile_p3">
                            So bekommen Sie einen Überblick über das Sicherheitsniveau Ihrer
                            Supply Chain und potentieller zukünftiger Geschäftspartner, ohne
                            überhaupt erst mit den Unternehmen in Kontakt treten zu müssen.
                        </Trans>
                    </PageText>
                </header>
            </PageSection>

            <ContactSection isDark={false} />
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["third-party-management"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default ThirdPartyManagement;
